import intlTelInput from 'intl-tel-input';

function phoneMask() {
    let input = document.querySelector("#phone22");
    intlTelInput(input);
}




module.exports = phoneMask;