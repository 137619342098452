
if (document.querySelector('.tabs')) {
    const tabsTitles = document.querySelectorAll('.tabs__titles span');
    const tabsContent = document.querySelectorAll('.tabs__content');
    

    tabsTitles.forEach((title, i) => {

        title.addEventListener('click', () => {
            
            tabsContent.forEach(content => {
                content.classList.remove('tabs__content--active');
            });

            tabsTitles.forEach(title => {
                title.classList.remove('tabs__title--active');
            });

            tabsContent[i].classList.add('tabs__content--active');
            title.classList.add('tabs__title--active');
        });

    });
}
