
function categoryMenu() {
    if (document.querySelector('.category__menu-toggler')) {
            const menu = document.querySelector('.category__menu-toggler');

                menu.addEventListener('click', function() {
                this.classList.add('category__menu-toggler--opened');
                document.querySelector('body').classList.add('no-scroll');
                // document.querySelector('body').style.paddingRight='1.7rem';
            });


            const categoryClose = document.querySelector('.category__close');

                categoryClose.addEventListener('click', function() {
                document.querySelector('.category__menu-toggler').classList.remove('category__menu-toggler--opened');
                document.querySelector('body').classList.remove('no-scroll');
                //  document.querySelector('body').style.paddingRight='unset';
            });
    }   
}

module.exports=categoryMenu;