

import intlTelInput from 'intl-tel-input';

function phoneMask2() {
    let input2 = document.querySelector("#shipping__phone22");
    intlTelInput(input2);

}




module.exports = phoneMask2; 