import './lib/tabs';
import menu from './lib/menu';
import categoryMenu from './lib/category-menu';
import './lib/sliders';
import fav from './lib/fav';

import phoneMask from './lib/phone';
import phoneMask2 from './lib/phone2';

menu();
fav();
categoryMenu();
phoneMask();
phoneMask2();
