function menu(){
    //burger open&close
    if (document.querySelector('.header__menu-toggler')) {
        if ($(window).width() < 1024){

            document.querySelector('.header__menu-toggler').addEventListener('click',(e)=>{
                
                e.preventDefault();
                document.querySelector('.header__menu-toggler').classList.toggle('header__menu-toggler--opened');	
                document.querySelector('.nav-menu__list-wrapper').classList.toggle('nav-menu__list-wrapper--opened');

                document.querySelector('body').classList.toggle('no-scroll');
            });


            document.querySelector('.nav-menu__second-link').addEventListener('click',()=>{
                document.querySelector('.nav-menu__second-link').classList.toggle('nav-menu__second-link--opened');
                document.querySelector('.nav-menu__second').classList.toggle('nav-menu__second--opened');
            });

                document.querySelector('.nav-menu__second-link:nth-of-type(2)').addEventListener('click',()=>{
                document.querySelector('.nav-menu__second-link:nth-of-type(2)').classList.toggle('nav-menu__second-link--opened');
                document.querySelector('.nav-menu__sec-item').classList.toggle('nav-menu__second--opened');
            });

        }
    }
}

module.exports=menu;