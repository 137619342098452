import Glide from '@glidejs/glide';

if (document.querySelector(".sl05")) {
    let produceSlider0=new Glide(".sl05", {
        type:'carousel',
        autoheight: true,
        hoverpause:true,
        animationDuration: 1000,
        perView: 1,
        breakpoints: {
            320: {
                perView: 1,
                gap: 10
            }
        }
    }).mount();

    if ($(window).width() > 1024) {
        produceSlider0.destroy();
    }        
}

if (document.querySelector(".produce__slider")) {
    let produceSlider1=new Glide(".produce__slider", {
        type:'carousel',
        autoheight: true,
        hoverpause:true,
        animationDuration: 1000,
        breakpoints: {
            767:{
                perView: 2,
                gap: 25,
            },
            1024:{
                perView:3,
                gap:25
            }

        }

    }).mount();

    if ($(window).width() > 1023) {
        produceSlider1.destroy();
    }

    $( window ).resize(function() {
        if ($(window).width() > 1023) {
            document.location.reload();
        }
    });
}
if (document.querySelector(".produce__slider2 ")) {
    let produceSlider2=new Glide(".produce__slider2", {
        type:'carousel',
        autoheight: true,
        hoverpause:true,
        animationDuration: 1000,
        breakpoints: {
            767:{
                perView: 2,
                gap: 25,
            },
            1024:{
                perView:3,
                gap:25
            }

        }

    }).mount();

    if ($(window).width() > 1023) {
        produceSlider2.destroy();
    }
    
    if ($(window).width() < 1023) {
        $('.tabs__titles span').click(function(){
        produceSlider2.update()
    });
    }
}
