function fav(){
    let favBtn= document.querySelectorAll('.favorites__btn');

    favBtn.forEach(elem =>{
        elem.addEventListener('click',()=>{
        elem.classList.toggle('favorites__btn--checked');
        });
    });

    if(document.querySelector('.produce__title-fav__btn')){
        let productBtn= document.querySelector('.produce__title-fav__btn');
            productBtn.addEventListener('click',()=>{
            productBtn.classList.toggle('produce__title-fav__btn--checked');

        });
    };

    //popup
if(document.querySelector('.produce-popup__wrapper')){
    let popupBtn = document.querySelector('.produce__size-button');
    popupBtn.addEventListener('click',()=>{
        document.querySelector('.produce-popup__wrapper').classList.add('produce-popup__wrapper--active');
        document.querySelector('.produce-popup__city-header svg').addEventListener('click',()=>{
        document.querySelector('.produce-popup__wrapper').classList.remove('produce-popup__wrapper--active');

        });
    });
}
}

module.exports=fav;